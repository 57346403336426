/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-25",
    versionChannel: "nightly",
    buildDate: "2025-01-25T00:05:57.751Z",
    commitHash: "21393733c9c88a5c9c0927dc534e3ef159403511",
};
